<template>
  <b-modal
    :title="$t('SendEmail')"
    size="xl"
    static
    :visible="showModal"
    hide-footer
    @change="(val) => {
      $emit('update:show-modal', val)

      if (!val) {
        mail = JSON.parse(JSON.stringify({
          generatedTargetContacts: 0,
          contactThreadFunnelTypePoints: [],
          name: '',
          targetContactStatuses: [],
          targetContactTypes: [],
          funnel: '',
          funnelGroupList: '',
          userMailbox: '',
          startAt: '',
          subject: '',
          content: '',
        }))
        funnel = ''
        funnelStatuses = []
        funnelGroupList = []

      }
    }"
  >
    <!-- Modal: Body -->
    <validation-observer
      v-if="showModal"
      ref="observer"
      v-slot="{invalid}"
      tag="form"
    >
      <!--      <h5>{{ $t('SpecifyTargetGroup') }}:</h5>-->
      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('SpecifyTargetGroup') }}
        </div>
      </div>

      <b-row style="row-gap: .5rem">
        <b-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Name')"
            rules="required"
          >
            <b-form-group :label="`${$t('Name')}*`">
              <b-form-input
                v-model.trim="mail.name"
                :placeholder="$t('Name')"
                :state="errors.length > 0 ? false:null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col lg="6">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('funnel.SelectContactStatus')"
            rules="required"
          >
            <sw-select :state="errors.length > 0 ? false:null"
                       class="mb-50"
                       :name="`${$t('funnel.SelectContactStatus')}*`"
            >
              <v-select
                v-model="mail.targetContactStatuses"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('funnel.SelectContactStatus')"
                label="name"
                :options="contactStatuses"
                multiple
              >
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>

                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>
              </v-select>
            </sw-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col lg="6">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('SelectContactTags')"
          >
            <sw-select :state="errors.length > 0 ? false:null"
                       :name="`${$t('SelectContactTags')}`"
            >
              <v-select
                v-model="mail.targetContactTypes"
                :options="threadTags"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('SelectContactTags')"
                multiple
                label="name"
              >
                <template #option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>

                <template #selected-option="{ name, color }">
                  <div
                    class="color-dot"
                    style="border: 1px solid white"
                    :style="{ backgroundColor: color }"
                  />
                  {{ name }}
                </template>

                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col sm="12">
          <b-form-checkbox
            v-model="mail.targetRequireAgreeMarketing"
            variant="primary"
            class="mt-50"
          >
            {{ $t('SelectingAcceptMarketing') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!--      <h5>{{ $t('SpecifyFunnels') }}:</h5>-->
      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('SpecifyFunnels') }}
        </div>
      </div>

      <b-row style="row-gap: .75rem">
        <b-col
          md="6"
          lg="5"
        >
          <sw-select
            :name="$t('funnel.SelectFunnel')"
            class="mb-0"
          >
            <v-select
              v-model="funnel"
              :options="funnelList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('funnel.SelectFunnel')"
              label="name"
              lazy
              :selectable="({ id }) => !funnelGroupList.find(fg => fg.funnel.id === id)"
              @input="funnelStatuses = ''"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          md="6"
          lg="5"
        >
          <sw-select
            :name="$t('SelectFunnelStatuses')"
            class="mb-0"
          >
            <v-select
              v-model="funnelStatuses"
              :options="funnel ? funnel.contactThreadFunnelTypePoints : []"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('SelectFunnelStatuses')"
              label="name"
              multiple
              :disabled="!funnel"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          lg="2"
          class="d-flex justify-content-end align-items-end"
        >
          <b-button
            v-b-tooltip.hover.v-primary
            size="md"
            variant="primary"
            class="w-100 btn-icon"
            :title="$t('AddAnotherFunnel')"
            :disabled="!funnel || !funnelStatuses"
            @click="addNewFunnelGroup()"
          >
            {{ $t('Add') }}

            <feather-icon
              icon="PlusIcon"
            />
          </b-button>
        </b-col>
      </b-row>

      <div
        v-if="funnelGroupList.length"
        class="mt-1 d-flex flex-column"
        style="row-gap: .75rem"
      >
        <b-alert
          v-for="(group, i) in funnelGroupList"
          :key="i"
          variant="primary"
          class="m-0"
          show
        >
          <h4
            class="alert-heading px-75 py-50 d-flex align-items-center justify-content-between"
            style="column-gap: .5rem"
          >
            <span
              class="d-flex align-items-center"
              style="column-gap: .5rem"
            >
              <feather-icon icon="FilterIcon" />

              {{ group.funnel.name }}
            </span>

            <b-button
              v-b-tooltip.hover.v-danger
              size="sm"
              variant="flat-danger"
              class="btn-icon p-25"
              :title="$t('Remove')"
              @click="funnelGroupList.splice(i, 1)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </h4>

          <div
            class="alert-body d-flex d-flex"
            style="gap: .5rem"
          >
            <b-badge
              v-for="(status, j) in group.funnelStatuses"
              :key="j"
              v-b-tooltip.hover.v-danger
              variant="primary"
              :title="$t('Remove')"
              class="cursor-pointer"
              @click="() => {
                funnelGroupList[i].funnelStatuses.splice(j, 1)

                if (!funnelGroupList[i].funnelStatuses.length)
                  funnelGroupList.splice(i, 1)
              }"
            >
              {{ status.name }}
            </b-badge>
          </div>
        </b-alert>
      </div>

      <b-card
        v-else
        class="text-center mt-2 pb-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </b-card>

      <!--      <b-alert-->
      <!--        :show="mail.id"-->
      <!--        variant="success"-->
      <!--        class="px-75 py-1 mt-1 d-flex align-items-center justify-content-between"-->
      <!--      >-->
      <!--        <div class="d-flex align-items-center">-->
      <!--          <b-avatar variant="success">-->
      <!--            <feather-icon icon="UsersIcon" />-->
      <!--          </b-avatar>-->

      <!--          <span class="d-flex flex-column ml-75">-->
      <!--            <strong>-->
      <!--              {{ $t('YourAudience') }}-->
      <!--            </strong>-->

      <!--            <span>-->
      <!--              {{ mail.generatedTargetContacts }}-->
      <!--              {{ $t('ContactsSettings').toLowerCase() }}-->
      <!--            </span>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </b-alert>-->

      <b-row :class="{ 'mt-1': !mail.id }">
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('gEmail.settings.EnterYourEmailAddress')"
            rules="required"
          >
            <sw-select :state="errors.length > 0 ? false:null"
                       class="mb-50"
                       :name="`${$t('gEmail.settings.EnterYourEmailAddress')}*`"
            >
              <v-select
                v-model="mail.userMailbox"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('gEmail.settings.EnterYourEmailAddress')"
                label="imapHost"
                :options="mailboxList"
                @option:selected="selectInbox"
              >
                <template #option="{ senderEmail, senderName }">
                  {{ senderEmail }} - {{ senderName | truncate(30) }}
                </template>
                <template #selected-option="{ senderEmail, senderName }">
                  {{ senderEmail }} - {{ senderName | truncate(30) }}
                </template>
                <template #no-options>
                  {{ $t('NoOptions') }}
                </template>
              </v-select>
            </sw-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <sw-select class="mb-50"
                     :name="`${$t('PlannedShippingDate')}*`"
          >
            <sw-select-fpr>
              <flat-pickr
                v-model="mail.startAt"
                :placeholder="$t('PlannedShippingDate')"
                class="form-control"
                :config="{ ...flatPickerConfig, locale: $i18n.locale, minDate: moment().add(1, 'hour').format('DD-MM-YYYY HH:mm') }"
              />
            </sw-select-fpr>
          </sw-select>

        </b-col>
      </b-row>

      <div class="divider text-primary">
        <div class="divider-text">
          {{ $t('MessageEmail') }}
        </div>
      </div>
      <div>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('EnterTitleOfEmail')"
          rules="required"
        >
          <b-form-group :label="`${$t('EnterTitleOfEmail')}*`">
            <b-form-input
              v-model.trim="mail.subject"
              :placeholder="$t('EnterTitleOfEmail')"
              :state="errors.length > 0 ? false:null"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </div>
      <!-- Field: Message - Quill Editor -->
      <div class="mt-1 mt-lg-0">
        <b-form-group :label="$t('Content')">
          <quill-editor
            ref="content-quill"
            v-model.trim="mail.content"
            style="min-height: 150px"
            :options="{ placeholder: '', modules: {
              toolbar: '#toolbar-content',
            }, }"
          >
            <div
              id="toolbar-content"
              slot="toolbar"
            >
              <!-- Add a bold button -->
              <span class="ql-formats">
                <button class="ql-clean" />
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-link" />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-script"
                  value="sub"
                />
                <button
                  class="ql-script"
                  value="super"
                />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-list"
                  value="ordered"
                />
                <button
                  class="ql-list"
                  value="bullet"
                />
              </span>
              <span class="ql-formats">
                <button
                  class="ql-indent"
                  value="-1"
                />
                <button
                  class="ql-indent"
                  value="+1"
                />
              </span>
              <button class="ql-image" />
<!--              <div class="ql-formats">-->
<!--                <b-button-->
<!--                  id="custom-button"-->
<!--                  variant="primary"-->
<!--                  :disabled="fileLoading"-->
<!--                  @click="$refs.image_input.click()"-->
<!--                >-->
<!--                  <span class="text-white">-->
<!--                    <feather-icon icon="ImageIcon" />-->
<!--                  </span>-->
<!--                  <input-->
<!--                    id="image_input"-->
<!--                    ref="image_input"-->
<!--                    style="display: none"-->
<!--                    type="file"-->
<!--                    accept="image/*"-->
<!--                    @change="uploadFile"-->
<!--                  >-->
<!--                </b-button>-->
<!--              </div>-->
            </div>
          </quill-editor>
        </b-form-group>
      </div>
      <div class="mt-1 mt-lg-0 ql-footer">
        <b-form-group :label="$t('Footer')">
          <div class="message-editor pb-3">
            <quill-editor
              id="quil-content"
              ref="footer_editor"
              v-model="mail.footer"
              style="height: 250px"
              :options="editorOption"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-link" />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-script"
                    value="sub"
                  />
                  <button
                    class="ql-script"
                    value="super"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  />
                  <button
                    class="ql-list"
                    value="bullet"
                  />
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-indent"
                    value="-1"
                  />
                  <button
                    class="ql-indent"
                    value="+1"
                  />
                </span>
              </div>
            </quill-editor>
          </div>

          <drag-drop-upload
            :default-value="mail.files"
            show-count
            :show-files="false"
            @onChangeFiles="changeFiles"
          />
          <!--      <b-form-textarea v-model="email.footer"-->
          <!--                       rows="4"-->
          <!--      />-->
        </b-form-group>
      </div>
      <div
        class="mt-1 mb-50 d-flex justify-content-end"
        style="gap: .5rem"
      >
        <b-button
          variant="secondary"
          :disabled="invalid || !mail.content"
          @click="send('DRAFT')"
        >
          {{ $t('Offer.SaveAsSketch') }}
        </b-button>

        <b-button
          variant="primary"
          :disabled="invalid || !mail.content"
          @click="send('PLANNED')"
        >
          {{ $t('Send') }}
        </b-button>
      </div>
    </validation-observer>

    <b-overlay
      :show="isLoading"
      no-wrap
      spinner-variant="success"
    />
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { VBTooltip } from 'bootstrap-vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import { mapGetters } from 'vuex'
import { SETTINGS_CONTACTS_STATUSES, SETTINGS_FUNNEL_TYPE, USER_MAILBOX } from '@/@constants/fields'
import {
  ADD_CAMPAIGNS, GET_CONTACT_STATUSES, GET_USER_MAILBOXES, UPDATE_CAMPAIGNS, UPLOAD_FILES,
} from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import keysCuter from '@/helpers/keysCuter'
import DragDropUpload from '@/views/components/DragDropUpload.vue'

const defaultMail = {
  generatedTargetContacts: 0,
  contactThreadFunnelTypePoints: [],
  name: '',
  targetContactStatuses: [],
  targetContactTypes: [],
  files: [],
  funnel: '',
  targetRequireAgreeMarketing: false,
  funnelGroupList: '',
  userMailbox: '',
  startAt: '',
  subject: '',
  content: '',
  footer: '',
}

export default {
  components: {
    DragDropUpload,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showModal',
    event: 'update:show-modal',
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    editedItem: {
      required: false,
      default: null,
    },
  },
  emits: ['update'],

  data: () => ({
    moment,
    isLoading: false,

    mail: JSON.parse(JSON.stringify(defaultMail)),
    footerImages: [],
    funnelStatuses: [],
    funnelGroupList: [],
    fileLoading: false,

    flatPickerConfig: {
      mode: 'single',
      enableTime: true,
      dateFormat: 'd-m-Y H:i',
      time_24hr: true,
      locale: [pl, en],
    },

    editorOption: {
      modules: {
        toolbar: '#toolbar',
      },
      placeholder: '',
    },

    funnel: null,

    type: 'EMAIL',

    contactStatuses: [],
    funnelList: [],
    mailboxList: [],
  }),
  watch: {
    editedItem: {
      deep: true,
      handler() {
        // console.log(nw)
      },
    },
  },

  computed: {
    ...mapGetters({
      threadTags: 'typeSettings/getThreadTags',
    }),

    locale() {
      return this.$i18n.locale
    },
  },

  async mounted() {
    await Promise.all([
      this.loadFunnels(),
      this.loadContactStatuses(),
      this.loadMailbox(),
    ])

    if (this.editedItem) {
      const { startAt, content, ...item } = this.editedItem
      this.mail = JSON.parse(JSON.stringify(item))

      if (startAt?.date) {
        this.mail.startAt = moment(startAt?.date, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm')
      } else {
        this.mail.startAt = new Date()
      }

      if (this.mail.imageFiles?.length) {
        let footerItem = this.mail.footer
        let contentHtml = content

        const responses = await Promise.all(this.mail.imageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
        this.footerImages = responses.map((e, i) => {
          const filename = `${e.data.data.name}`
          const base64 = `data:image/png;base64,${e.data.data.file}`
          return {
            token: this.mail.imageFiles[i]?.token,
            base64,
            cid: filename,
            filename,
            size: this.mail.imageFiles[i]?.size,
          }
        })

        this.footerImages.forEach(image => {
          // cid, filename, token, base64
          if (footerItem.includes(image.cid)) {
            footerItem = footerItem.replaceAll(`cid:${image.filename}`, image.base64)
          }

          if (contentHtml.includes(image.cid)) {
            contentHtml = contentHtml.replaceAll(`cid:${image.filename}`, image.base64)
          }
        })

        this.mail.footer = footerItem
        this.mail.content = contentHtml
      } else {
        if (this.editedItem.footer) {
          this.mail.footer = this.editedItem.footer
        }
        if (this.editedItem.content) {
          this.mail.content = this.editedItem.content
        }
      }

      // this.selectInbox(this.editedItem.userMailbox)

      this.mail.contactThreadFunnelTypePoints.forEach(point => {
        this.funnelList.forEach((funnel, funneIndex) => {
          if (funnel.contactThreadFunnelTypePoints.find(e => e.id === point.id)) {
            this.funnelList[funneIndex].existedPoints.push(point)
          }
        })
      })

      this.funnelList.filter(e => e.existedPoints?.length).forEach(funnel => {
        this.funnelGroupList.push({
          funnel,
          funnelStatuses: funnel.existedPoints,
        })
      })
    }
  },
  methods: {
    async uploadFile(inputResponse) {
      this.fileLoading = true
      const { files } = inputResponse.target

      const imageData = {
        token: null,
        base64: '',
        cid: '',
        filename: '',
      }

      const formData = new FormData()
      files.forEach(file => {
        const extension = file.name.split('.').at(-1)
        const uniqueId = `img${Math.random().toString(36).substring(2, 15)}`
        // const name = file.name.replace(`.${extension}`, '').replace(`${extension}`, '')
        formData.append('files[]', file, `${uniqueId}.${extension}`)
        imageData.cid = uniqueId
        imageData.filename = uniqueId
        imageData.size = file.size
      })
      const response = await axiosIns.post('storage/1/upload', formData, { noApi: true })

      // eslint-disable-next-line prefer-destructuring
      imageData.token = response.data.data.files[0]

      const fr = new FileReader()

      fr.onload = () => {
        imageData.base64 = fr.result
        const range = this.$refs['content-quill'].quill.getSelection()
        if (range) {
          this.$refs['content-quill'].quill.insertEmbed(range.index, 'image', imageData.base64)
          this.footerImages.push(imageData)
          this.$refs.image_input.value = null
          this.fileLoading = false
        }
      }

      fr.readAsDataURL(files[0])
    },
    changeFiles(files) { this.mail.files = files },
    addNewFunnelGroup() {
      const { funnel, funnelStatuses } = this

      if (!funnel || !funnelStatuses) return

      this.funnelGroupList.push({
        funnel,
        funnelStatuses,
      })

      this.funnel = ''
      this.funnelStatuses = []
    },

    async searchContactStatuses(search, isLoading) {
      this.contactStatuses = await this.selectSearch(search, isLoading, SETTINGS_CONTACTS_STATUSES, 'name', '1/settings/contactStatuses', 20)
    },
    async loadContactStatuses() {
      this.loading = true

      try {
        const { data } = await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
        this.contactStatuses = data.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },

    async searchFunnels(search, isLoading) {
      this.funnelList = await this.selectSearch(search, isLoading, SETTINGS_FUNNEL_TYPE, 'name', '1/settings/contactThreadFunnelTypes', 20)
    },
    async loadFunnels() {
      try {
        const response = await axiosIns.get('1/settings/contactThreadFunnelTypes', {
          params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' },
        })

        if (response?.data?.data?.items) this.funnelList = (response.data.data.items || []).map(e => ({ ...e, existedPoints: [] }))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async searchMailbox(search, isLoading) {
      this.mailboxList = await this.selectSearch(search, isLoading, USER_MAILBOX, 'name,imapHost', '1/users/me/mailboxes', 20)
    },
    async loadMailbox() {
      try {
        const { items } = await this.$store.dispatch(`mailbox/${GET_USER_MAILBOXES}`)
        this.mailboxList = items.filter((a => a?.active))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },

    async send(status) {
      this.isLoading = true

      await this.save(status)
    },

    async save(status) {
      this.isLoading = true

      const payload = JSON.parse(JSON.stringify(this.mail))

      payload.targetContactStatuses = this.mail.targetContactStatuses.map(e => this.getObjectId(e))
      payload.targetContactTypes = this.mail.targetContactTypes.map(e => this.getObjectId(e))
      payload.userMailbox = this.getObjectId(this.mail.userMailbox)
      payload.status = status || ''
      payload.type = this.type
      delete payload.generatedTargetContacts
      delete payload.campaignTargets
      delete payload.statusUpdatedAt

      const imagesCopy = this.footerImages.map(e => ({ ...e, finded: false }))
      imagesCopy.forEach((image, index) => {
        // cid, filename, token, base64
        if (payload.footer.includes(image.base64)) {
          payload.footer = payload.footer.replaceAll(image.base64, `cid:${image.filename.replaceAll('.png', '').replaceAll('.jpg', '').replaceAll('.jpeg', '').replaceAll('.webp', '')}`)
          imagesCopy[index].finded = true
        }
        if (payload.content.includes(image.base64)) {
          payload.content = payload.content.replaceAll(image.base64, `cid:${image.filename.replaceAll('.png', '').replaceAll('.jpg', '').replaceAll('.jpeg', '').replaceAll('.webp', '')}`)
          imagesCopy[index].finded = true
        }
      })

      if (this.mail.files.length) {
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: this.mail.files.filter(e => !e.token), type: 'building' })
        payload.files = [...files, ...this.mail.files.map(e => e?.token).filter(Boolean)].filter(Boolean)
      }
      payload.imageFiles = imagesCopy.filter(e => e.finded).map(e => e.token)

      keysCuter(payload, ['campaignTargets'])

      payload.contactThreadFunnelTypePoints = []
      // eslint-disable-next-line
      for (const fg of this.funnelGroupList) {
        // eslint-disable-next-line
        payload.contactThreadFunnelTypePoints.push(...fg.funnelStatuses.map(e => this.getObjectId(e)))
      }

      const [parsedContentFooter, tokens = [], size = 0] = await this.scanContentAndReplaceBaseWithCid(payload.footer)
      const [parsedContentContent, tokensContent = [], sizeContent = 0] = await this.scanContentAndReplaceBaseWithCid(payload.content)

      if (tokens.length || tokensContent.length) {
        payload.imageFiles.push(...tokens, ...tokensContent)

        payload.footer = parsedContentFooter
        payload.content = parsedContentContent
      }

      // check total files size
      if (imagesCopy?.length || this.mail.files?.length) {
        const allFiles = [
          ...(imagesCopy || []).filter(e => e.finded),
          ...(this.mail.files || []),
        ]

        const totalSize = allFiles.reduce((a, b) => +a + +(b.size || 0), size + sizeContent)
        const mb = (totalSize / (1024 * 1024)).toFixed(2)

        if (mb > 25) {
          this.showToast('error', this.$i18n.tc('YourFilesSizeIsBiggerThanMb', 1, { allowedSize: 25, filesSize: mb }))
          this.isLoading = false
          return
        }
      }
      // eslint-disable-next-line no-unreachable
      const acion = payload.id ? UPDATE_CAMPAIGNS : ADD_CAMPAIGNS
      this.$store.dispatch(`campaigns/${acion}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.contactUpdated'))
          this.$emit('update:show-modal', false)
          this.$emit('update')
          this.mail = JSON.parse(JSON.stringify({
            generatedTargetContacts: 0,
            contactThreadFunnelTypePoints: [],
            name: '',
            targetContactStatuses: [],
            targetContactTypes: [],
            funnel: '',
            targetRequireAgreeMarketing: false,
            funnelGroupList: '',
            userMailbox: '',
            startAt: '',
            subject: '',
            content: '',
          }))
          this.funnel = ''
          this.funnelStatuses = []
          this.funnelGroupList = []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      // eslint-disable-next-line no-unreachable
      this.isLoading = false
    },
    async selectInbox(event) {
      if (event.footer) {
        /* eslint-disable */
        let footer = event.footer

        if (event.footerImageFiles.length) {
            const responses = await Promise.all(event.footerImageFiles.map(image => axiosIns.get(`storage/1/download/${image.token}`)))
            this.footerImages = responses.map((e, i) => {
                const filename = `${e.data.data.name}`
                const base64 = `data:image/png;base64,${e.data.data.file}`
                return {
                    token: event.footerImageFiles[i]?.token,
                    base64,
                    cid: filename,
                    filename,
                    size: event.footerImageFiles[i]?.size,
                }
            })

            this.footerImages.forEach(image => {
                // cid, filename, token, base64
                if (footer.includes(image.cid)) {
                    footer = footer.replaceAll(`cid:${image.filename}`, image.base64)
                }
            })
        }

        this.mail.footer = footer
        } else {
          this.mail.footer = ''
        }
      }
  },
}
</script>

<style lang="scss">
.ql-container {
  min-height: 300px;
}

.ql-footer .ql-container {
  min-height: 150px !important;
}
</style>
